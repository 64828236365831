import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ScrollContainer from 'react-indiana-drag-scroll'
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";

const PortfolioHeader = () => (
  <section className="bg-gray-700 py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Jus Workshop</p>
              <small className="text-muted">
                Jus Workshop is a fake company name to protect our client’s
                identity.
              </small>
            </div>
            <div className="col-12 mt-0 mt-lg-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">Backend Development</p>
              <p className="text-white">Frontend Development</p>
              <p className="text-white">UI/UX Services</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white mb-0">
            729 SOLUTIONS BUILT A CHECKOUT AND ACCOUNT MANAGEMENT POWERED BY
            RECURLY. OUR SOLUTIONS INTEGRATED SEAMLESSLY WITH THEIR EXISTING
            BACKEND TO SYNC ORDERS, AND RETRIEVE OVERAGE CHARGES.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const LaptopImage = () => (
  <section className="bg-secondary-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StaticImage
            src="../../images/portfolio/jus/workshop-hero.png"
            alt="Jus Workshop Sample"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>
            Jus Workshop required a solution that integrated with Recurly. One
            that allowed users to purchase subscriptions and provided guidance
            to them on which may best fit their needs. They also needed an
            account management system where customers can manage their
            subscriptions and billing information. Account management included
            the ability to change subscription levels during a subscription
            period to support varying plan usage requirements. They needed
            integrations with their existing backend to sync subscriptions,
            orders, and retrieve overage charges
          </p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>Project Goals</h2>
          <ol className="text-primary">
            <li>
              <span className="text-dark">
                Build an intuitive and powerful checkout experience.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Provide a robust account management system.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Review all provided UI assets and UX workflows.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Seamlessly integrate with their pre-existing system.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Display unique functionality dependent on the customers physical
                location.
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);

const HowWeDidIt = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Software Used</h2>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/sketch.svg"
                        alt="Sketch"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-xd.png"
                        alt="Adobe Xd"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/vscode.svg"
                        alt="Visual Studio Code"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/gitlab.png"
                        alt="Gitlab"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/invision.png"
                        alt="Invision"
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">Backend Libraries & Frameworks Used</h2>
              <p className="mb-0">HTML 5</p>
              <p className="mb-0">CSS 3</p>
              <p className="mb-0">JavaScript</p>
              <p className="mb-0">python</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">
                FRONTEND LIBRARIES & FRAMEWORKS USED
              </h2>
              <p className="mb-0">React</p>
              <p className="mb-0">React Bootstrap</p>
              <p className="mb-0">Redux</p>
              <p className="mb-0">Redux Form</p>
              <p className="mb-0">ImmutableJS</p>
              <p className="mb-0">Date-fns</p>
              <p className="mb-0">Axios</p>
              <p className="mb-0">React Flexbox Grid</p>
              <p className="mb-0">Prop Types</p>
              <p className="mb-0">Storybook</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Research Methods</h2>
              <div className="row align-items-center">
                <div className="col-2">
                  <svg
                    color="gray"
                    id="b04abcf6-915e-4101-a30d-579f98f82595"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="a9715317-253e-4e2a-9b8f-e42433a04d67"
                      data-name="icon user flow"
                    >
                      <g
                        id="bbf3f5c3-462d-4c27-ab52-ff2656d3f399"
                        data-name="Ellipse 18"
                      >
                        <circle
                          cx="25.64"
                          cy="70.7"
                          r="11.09"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <g
                        id="a89ccc62-2133-43c4-9962-3bf96806c48b"
                        data-name="Ellipse 19"
                      >
                        <circle
                          cx="118.36"
                          cy="72.51"
                          r="11.09"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <path
                        id="a682776c-d2f6-4f16-bbb7-868e47900dc3"
                        data-name="Path 6"
                        d="M36,76.05s17.62,7.16,37.23-6.49,35-3.53,35-3.53"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-dasharray="7.39"
                      />
                    </g>
                  </svg>
                </div>
                <div className="col-10">
                  <p>Customer Journey</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-2">
                  <svg
                    color="gray"
                    id="a4c3def4-a24b-430b-b2bf-0125261cc8d5"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="b5f1929a-4557-4d8b-b596-255d0dd4b2a9"
                      data-name="layout-11-2"
                    >
                      <rect
                        id="b5f6b235-2a7b-4b52-9818-308bdfa85d99"
                        data-name="Rectangle 133-2"
                        x="17.49"
                        y="12.02"
                        width="48.25"
                        height="70.04"
                        transform="translate(-0.07 0.06) rotate(-0.09)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <rect
                        id="ba5ed7db-74ba-4523-9ba6-19810ee7523d"
                        data-name="Rectangle 134-2"
                        x="17.59"
                        y="94.51"
                        width="48.25"
                        height="38.91"
                        transform="translate(-0.17 0.06) rotate(-0.09)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <rect
                        id="f5a2794e-b30b-49d6-bf7d-8e3b6c007e98"
                        data-name="Rectangle 135-2"
                        x="78.16"
                        y="11.93"
                        width="48.25"
                        height="38.91"
                        transform="translate(-0.05 0.15) rotate(-0.09)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <rect
                        id="b0a66014-86a2-494c-8f4b-ab701da6307a"
                        data-name="Rectangle 136-2"
                        x="78.26"
                        y="63.29"
                        width="48.25"
                        height="70.04"
                        transform="translate(-0.15 0.15) rotate(-0.09)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
                <div className="col-10">Prototype Testing</div>
                <div className="col-2">
                  <svg
                    color="gray"
                    id="b64d49f6-b75d-4e3c-b7f4-0175706789f5"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <rect
                      x="52.84"
                      y="14.69"
                      width="38.33"
                      height="38.33"
                      rx="6.86"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <rect
                      x="21.48"
                      y="105.67"
                      width="23.64"
                      height="23.64"
                      rx="4.23"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <rect
                      x="60.18"
                      y="105.67"
                      width="23.64"
                      height="23.64"
                      rx="4.23"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <rect
                      x="98.88"
                      y="105.67"
                      width="23.64"
                      height="23.64"
                      rx="4.23"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M32.53,97.73V88.4a9.87,9.87,0,0,1,9.84-9.84h59.26a9.87,9.87,0,0,1,9.84,9.84v9.33"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <line
                      x1="72"
                      y1="97.73"
                      x2="72"
                      y2="59.77"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                  </svg>
                </div>
                <div className="col-10">
                  <p>Information Architecture</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="mt-5">Tools</h2>
              <p className="mb-0">Docker</p>
              <p className="mb-0">Prometheus</p>
              <p className="mb-0">Segment.io</p>
              <p className="mb-0">Kubernetes</p>
              <p className="mb-0">SumoLogic </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const FirstCarousel = () => (
  <section className="bg-secondary-light">
    <ScrollContainer vertical={false} className="scroll-carousel py-6">
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step1.png"
        alt="Workshop Step 1"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step2.png"
        alt="Workshop Step 2"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step3.png"
        alt="Workshop Step 3"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step4.png"
        alt="Workshop Step 4"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step5.png"
        alt="Workshop Step 5"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step6.png"
        alt="Workshop Step 6"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step7.png"
        alt="Workshop Step 7"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
      <StaticImage
        layout="fixed"
        height="650"
        src="../../images/portfolio/jus/workshop-step8.png"
        alt="Workshop Step 8"
        className="scroll-carousel-item"
        imgClassName="scroll-carousel-img"
      />
    </ScrollContainer>
  </section>
);

const FocusAreasAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Key Focus Areas</h2>
          <p>How do we provide an intuitive checkout experience?</p>
          <p>
            How can we provide an account management system that checks all the
            boxes?
          </p>
          <p>
            How do we apply industry-leading user experience practices to an
            intricate checkout process?
          </p>
          <p>
            How do we leverage our partnership with Recurly to deliver a
            successful migration from Stripe?
          </p>
        </div>
        <div className="col-12 col-lg-6">
          <h2>Core Client Needs</h2>
          <p>
            <b>Checkout Experience</b>
          </p>
          <p>
            <b>User Migration</b>
          </p>
          <p>
            <b>Seamless integration to existing systems</b>
          </p>
          <p>
            <b>Account Management</b>
          </p>
          <p>
            <b>UI/UX Consulting</b>
          </p>
        </div>
      </div>
    </div>
  </section>
);

const SecondCarousel = () => (
  <section className="bg-primary-alternative py-6">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 align-self-center">
          <StaticImage
            src="../../images/portfolio/jus/workshop-invoices-list1.png"
            alt="Invoices 1"
          />
        </div>
        <div className="col-md-6 align-self-center">
          <StaticImage
            src="../../images/portfolio/jus/workshop-invoices-list2.png"
            alt="Invoices 2"
          />
        </div>
      </div>
    </div>
  </section>
);

const Opportunities = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Opportunities</h2>
          <p>
            This project allowed 729 Solutions an opportunity to build powerful
            products for an industry leading company. These opportunities
            included:
          </p>
          <ul className="text-primary"></ul>
          <li>
            <span className="text-dark">
              Build an intuitive and powerful checkout experience.
            </span>
          </li>
          <li>
            <span className="text-dark">
              Provide a robust account management system.
            </span>
          </li>
          <li>
            <span className="text-dark">
              Review all provided UI assets and UX workflows.
            </span>
          </li>
          <li>
            <span className="text-dark">
              Seamlessly integrate with their pre-existing system.
            </span>
          </li>
          <li>
            <span className="text-dark">
              Display unique functionality dependent on the physical location of
              their customer..
            </span>
          </li>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location} />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Jus Workshop" 
      />
      <PortfolioHeader />
      <LaptopImage />
      <ProjectGoals />
      <HowWeDidIt />
      <FirstCarousel />
      <FocusAreasAndNeeds />
      <SecondCarousel />
      <Opportunities />
      <ProjectForm location={location}/>
      <PortfolioFeed className="my-6" />
    </Layout>
  );
};

export default Index;
